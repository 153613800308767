import React from 'react'
import styles from './Fleet.module.scss'
import { motion } from "framer-motion"


const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .6}
    } 
}

export const Fleet = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='Tarify'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.2, once: true}}
            >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Наш <span>автопарк</span></motion.h2>
                    <motion.div className={styles.box} variants={boxAnimation}>
                        <p className={styles.text}><span>Вакуумные машины Камаз, ГАЗ, МАЗ </span> предназначены для вакуумной очистки выгребных ям в частном секторе,  сбору жидких отходов, откачки воды из котлованов при аварийных работах на водопроводах и теплотрассах, очистки канализационных колодцев, откачка ила, откачки грязной воды с илом, а так же могут использоваться для механизированного заполнения, транспортировки и выгрузки любых технических жидкостей, не содержащих взрывоопасных и горючих веществ.</p>
                        <p className={styles.text}>Заполнение цистерны осуществляется под действием вакуума. Внутри емкости при помощи вакуумного насоса создается вакуум, за счет которого через специальный рукав жидкость всасывается внутрь цистерны. Опорожнение цистерны может осуществляться самотёком или давлением воздуха от вакуумного насоса.</p>
                        <p className={styles.text}>На большинстве наших автомобилей установлены мощные насосы JUROP DL  – это насос объемного вытеснения среды, выполненный известными итальянскими разработчиками. Насосами данной фирмы можно производить откачку воды с глубины до 15 метров. Отсутствие смазки в насосной камере исключает появление масляных пятен на месте работы вакуумной машины. При отдаленном нахождении объекта,  возможно  нарастить длину рукава до 100 метров.</p>                    
                    </motion.div>
            </div>   
        </motion.section>
    )
}