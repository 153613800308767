import React from 'react'
import styles from './Deadlines.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Deadlines/img_1.svg'
import image_2 from '../../image/Deadlines/img_2.svg'
import image_3 from '../../image/Deadlines/img_3.svg'
import image_5 from '../../image/Deadlines/img_5.svg'
import image_6 from '../../image/Deadlines/img_6.svg'

export const Deadlines = ({isOpen}) => {

    const titleAnimation = {
        hidden: {
            y: 200,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5 }
        }
    }
    
    const boxAnimation = {
        hidden: {
            y: 500,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .9}
        } 
    }

    return (
        <motion.section 
            className={styles.main}
            id='Deadlines' 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.15, once: true}}
        >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Порядок<span> оформления заказа</span></motion.h2>
            <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                            <p className={styles.box_figure}>1</p>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_1} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Заявка</h3>
                            <p className={styles.item_text}>Вы оставляете электронную заявку на сайте, электронной почте, мессенджере или звоните нам по телефону</p>
                        </li>
                        <li className={styles.item}>
                        <p className={styles.box_figure}>2</p>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_2} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Консультация</h3>
                            <p className={styles.item_text}>Уточняем особенности вашего обьекта, обьем работ и производим расчет стоимости работ</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.box_figure}>3</p>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Предоплата</h3>
                            <p className={styles.item_text}>В случае, если вы являетесь юридическим лицом, оплачиваете пятьдесят процентов оговоренной стоимости работ</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.box_figure}>4</p>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_6} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Выезд</h3>
                            <p className={styles.item_text}>В назначенные дату и время осуществляется выезд машины и специалистов по заявленному адресу</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.box_figure}>5</p>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_5} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Работы</h3>
                            <p className={styles.item_text}>Самый дешевый вариант - сразу сделать хорошо! Мы профессионально и качественно выполняем все оговоренные работы</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.box_figure}>6</p>
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Оплата</h3>
                            <p className={styles.item_text}>Вы оплачиваете остаток стоимости выполненных работ, и мы предоставляем гарантию на проведенные нами работы</p>
                        </li>
                    </motion.ul>
                    <button className={styles.button} type='button' onClick={isOpen}>оставить заявку <span className={styles.glare}></span></button>
            </div>   
        </motion.section>
        
        
    )
}
