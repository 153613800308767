import React from 'react'
import styles from './Map.module.scss'
import { motion } from "framer-motion"
import DetailMap from '../DetailMap/DetailMap'
import phone from '../../image/Map/phone.svg'
import email from '../../image/Map/email-icon.svg'
import address from '../../image/Map/address.svg'
import time from '../../image/Map/time.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Map = () => {


    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            id='kontakty'
            >
            <motion.div className={styles.box}>
                <div className={styles.info}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Контакты<span> ОТКАЧКА&#160;СЕРВИС &#160;</span></motion.h2>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                            </div>
                            <a className={styles.link_phone}  href="tel:+79166382281" target='_blank' rel='noopener noreferrer'>+7(916)638-22-81</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={email} alt = 'icon'/>
                            </div>
                            <a className={styles.link_email}  href="mailto:otkachka-service@yandex.ru" target='_blank' rel='noopener noreferrer'>otkachka-service@yandex.ru</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={address} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>г. Королев, Дворцовый проезд, д. 4</p>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts_social}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={time} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>Пн-Вс 00:00 - 24:00</p>
                    </motion.div>
                </div>
                <motion.div 
                    className={styles.box_map}
                    variants={titleAnimation} 
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.05, once: true}}
                    >
                    <DetailMap/>
                </motion.div>
            </motion.div>
        </motion.section>
    )
}

export default Map