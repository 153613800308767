import React from 'react'
import styles from './Cars.module.scss'
import { motion } from "framer-motion"
import icon_1 from '../../image/Cars/img_1.webp'
import icon_2 from '../../image/Cars/img_2.webp'
import icon_3 from '../../image/Cars/img_3.webp'

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const Cars = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <div className={styles.box}>
                    <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_1} alt='асенизатор'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Вакуумные машины</h3>
                                <p className={styles.item_text}>Вакуумные машины объемом от 5 м³ до 16 м³</p>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <img className={styles.item_image}  src={icon_2} alt='асенизатор'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Илососные машины </h3>
                                <p className={styles.item_text}>Илососные комбинированные машины с объемом цистерн под ил от 9 м³ до 15 м³, оборудованные вакуумным насосом итальянского производства производительностью 1050м.куб./час и насосом высокого давления для размыва илового осадка   </p>
                            </div>
                        </li>
                        <li className={styles.item}>
                            <img className={styles.item_image}  src={icon_3} alt='асенизатор'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Каналопромывочные машины</h3>
                                <p className={styles.item_text}>Каналопромывочные машины оборудованные итальянскими насосами высокого давления производительностью 212л/мин,  давлением 160Бар, для гидродинамической промывки канализационных труб диаметром до 1000мм.</p>
                            </div>
                        </li>
                    </motion.ul>
                </div>
        </motion.section>
        
    )
}