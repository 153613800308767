import React from 'react'
import styles from './Main.module.scss'
import { InitialPage } from '../../components/initialPage/InitialPage'
import {SliderAdvantages} from '../../components/SliderAdvantages/SliderAdvantages'
import { AboutUs } from '../../components/AboutUs/AboutUs'
import { Questions } from '../../components/Questions/Questions'
import {Advantages} from '../../components/Advantages/Advantages'
import { Territory } from '../../components/Territory/Territory'
import { Fleet } from '../../components/Fleet/Fleet'
import { Callback } from '../../components/Callback/Callback'
import { Practical } from '../../components/Practical/Practical'
import {Services} from "../../components/Services/Services"
import {Deadlines} from "../../components/Deadlines/Deadlines"
import { Cars } from '../../components/Cars/Cars'
import Map from '../../components/Map/Map'

export const Main = ({isOpen, isImageOpen, popupOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <SliderAdvantages/>
            <AboutUs
                isOpen={isOpen}
            />
            <Services
                isOpen={isOpen}
            />
            <Advantages
                isOpen={isOpen}
            />
            <Territory/>
            <Deadlines
                isOpen={isOpen}
            />
            <Questions/>
            <SliderAdvantages/>
            <Callback
                popupOpen={popupOpen}
            />
            <Fleet/>
            <Cars/>
            <Practical/>
            <Map/>
        </section>
    )
}