import React from 'react'
import styles from './Territory.module.scss'
import { motion } from "framer-motion"


const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .6}
    } 
}

export const Territory = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='Tarify'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.2, once: true}}
            >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Территория <span>оказания услуг</span></motion.h2>
            <motion.p className={styles.subtitle} variants={titleAnimation}>Приедем и качественно выполним свою работу в городах, мкр., снт и поселках:</motion.p>
                    <motion.div className={styles.box} variants={boxAnimation}>
                        <p className={styles.text}><span>Королёв: </span> Болшево, Валентиновка, Первомайский, Текстильщик, Финский, Каховка, Оболдино, снт Самаровка, снт Стандарт, снт Ивантеевка, Торфпредприятие, Лесные поляны, Зеленый бор, Челюскинский, Тарасовка, Любимовка, 43 квартал, Лестех, Бурково, Васильевское, Мальцево, Хомутово, Краснознаменский, Образцово, Дворянское озеро, агорянский, Королев, Королев мкр.первомайский, Королев мкр.Текстильщики, Королев мкр.Финский</p>
                        <p className={styles.text}><span>Пушкино: </span>  Акулово, Алёшино, Ашукино, Братовщина, Ельдигино, ЗаветыИльича, Зверосовхоз, Звягино, Зеленоградский, Зеленыйбор, Ивантеевка, Кавезино, Клязьма, Комягино, Кощейково, Красноармейск, Кудринка, Левково, Мамонтовка, Митрополье, Нагорное, Невзорово, Новаядеревня, Правдинский, Пушкино, Софрино, Степаньково, Тарасовка, Тишково, Царево, Царёво, Черкизово</p>
                        <p className={styles.text}><span>Ивантеевка, Фрязино: </span>  Левково, Комягино, Грибово, Невзорово, Детская, Южный, Байбаки, Набережная, Ветеран Космоса, снт Полет</p>
                        <p className={styles.text}><span>Щелково: </span>  Алмазово, Анискино, Аничково, Биокомбинат, Богослово, БольшиеЖеребцы, Гребнево, ДолгоеЛедово, Душоново, Жегалово, Загорянский, Звездныйгородок, Здехово, Кармолино, Кишкино,Клюквенный, Комягино, Леониха, Литвиново, Лосино-Петровский, МалоеЛедово, Мальцево, Медвежьи озера, Мизиново, Мишнево, Монино, Моносеево, Набережная, Назимиха, Никифорово, Новая Слобода, Новый городок, Оболдино, Огуднево, Орловка, Орловское, Осеевская, Петровское, пос.Биокомбината, Потапово, Протасово, Райки, Сабурово, Свердловский, Серково, Соколово, Солнцево, Сукманиха, Супонево, Топорково, Трубино, Улиткино, Фрязино, Хомутово, Хотово, Чкаловская, Шевёлкино, Щелково, Юность</p>
                        <p className={styles.text}><span>Мытищи: </span>  Лестех, Перовская, Тайнинская, Дружба, Челобитьево, снт Водоканал, Лестех, Челюскинский, Высоково, Свиноедово, Зимино, Каргашино, Пирогово, Пироговский, Подрезово, Болтино, Погорелки, Беляниново, Вешки, Ховрино, Бородино, Терпигорьево, Поведники, Афанасово, Троицкое, Юдино, Манюхино</p>                        
                    </motion.div>
            </div>   
        </motion.section>
    )
}