import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Advantages = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.1, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Почему <span> выбирают нас?</span></motion.h2>
        <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                        <p className={styles.number}>01</p>
                        <h3 className={styles.item_title}>Собственный автопарк</h3>
                        <p className={styles.item_text}> Огромный собственный парк современной спецтехники, который всегда готов прийти Вам на помощь раньше других.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>02</p>
                        <h3 className={styles.item_title}>Огромный опыт</h3>
                        <p className={styles.item_text}>Мы не компания однодневка, завтра и через год наш контактный номер не будет «не в зоне доступа» или выключен. Работаем с 2001 года и решаем трудные подчас нереальные задачи.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>03</p>
                        <h3 className={styles.item_title}>Демократичные цены</h3>
                        <p className={styles.item_text}>Лучшее предложение цены и качества представляемых услуг. Мы не посредники – мы исполнители. Обращаясь к нам напрямую, Вы получаете самую низкую цену, избегая услуг посредников.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>04</p>
                        <h3 className={styles.item_title}>Профессиональный подход</h3>
                        <p className={styles.item_text}>Профессиональные менеджеры, которые не первый год работают с откачкой, помогут Вам правильно подобрать необходимую машину и готовы проконсультировать Вас по любой оказываемой нами услуге.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>05</p>
                        <h3 className={styles.item_title}>Удобный расчет</h3>
                        <p className={styles.item_text}>Работаем за наличные и безналичный расчет. Заключаем договора с компаниями на разовые и постоянные услуги.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>06</p>
                        <h3 className={styles.item_title}>Наличие лицензий</h3>
                        <p className={styles.item_text}>Лицензия на осуществление деятельности по сбору, транспортированию, обработке, утилизации отходов I-IV классов опасности.</p>
                    </li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}