import React from 'react'
import styles from './MenuMobile.module.scss'
import logo from '../../image/Header/logo.svg'

export const MenuMobile = ({onClose, isMenuMobile}) => {

    return (
        <div className={`${isMenuMobile === true ? styles.main : styles.main_open}`}>
            <div className={styles.box}>
                <img className={styles.logo} src={logo} alt = 'логотип'/>
                <button className={styles.button} onClick={onClose} aria-label='закрыть меню'/>
            </div>
            <nav className={styles.nav}>
                    <a className={styles.link} href='#AboutUs' onClick={onClose}>О нас</a>
                    <a className={styles.link} href='#Services' onClick={onClose}>Услуги</a>
                    <a className={styles.link} href='#Advantages' onClick={onClose}>Преимущества</a>
                    <a className={styles.link} href='#Deadlines' onClick={onClose}>Как заказать</a>
                    <a className={styles.link} href='#Voprosy' onClick={onClose}>Вопросы и ответы</a>
                    <a className={styles.link} href='#kontakty' onClick={onClose}>Контакты</a>
            </nav>
        </div>
    );
}
